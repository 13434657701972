import './App.css';
import { DBTest } from './components/DBTest';
import { BSLGraph } from './components/BSLGraph';

function App() {
  return (

    <div>

      <DBTest></DBTest>
      <BSLGraph></BSLGraph>

    </div>
  );
}

export default App;
