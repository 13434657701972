import { useEffect, useState } from "react";
import { BSLGraph } from "./BSLGraph";
import HighchartsReact from "highcharts-react-official";


type BloodGlucoseLevel = {
    timestamp: number,
    mmol_litre: number
};

export const DBTest = () => {

    const [data, setData] = useState([] as [number, number][]);

    useEffect(() => {

        fetch("/healthdb")
            .then((res) => res.json())
            .then((responseBody) => {

                const results = responseBody as BloodGlucoseLevel[];

                const parsed: [number, number][] = [];

                if (results && results.length > 0) {
                    results.map((r) => parsed.push([r.timestamp, r.mmol_litre]));
                }

                setData(parsed);
            });

    }, []);




    console.log(data);

    const highchartsOpts: HighchartsReact.Props = {
        options: {
            title: {
                text: 'Blood Glucose Levels'
            },
            xAxis: {
                type: 'datetime'
            },
            yAxis: {
                title: {
                    text: 'Blood Glucose Level'
                }
            },
            legend: {
                enabled: false
            },
            series: [{
                type: 'line',
                data: data
            }]
        }
    }


    if (data.length > 0) {

        return (

            <div>
                <h1>DBTest</h1>
                <BSLGraph options={highchartsOpts.options}></BSLGraph>
            </div>
        );
    }

    return (
        <h1>No Data</h1>
    )


};
